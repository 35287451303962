<template>
  <multiselect
    :filterable="true"
    :options="options"
    v-model="selected"
    :custom-label="label"
    :placeholder="placeholder"/>
</template>
<script>

import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    draw: {
      type: Number,
    },
    placeholder: {
      type: String
    },
    value: {
      type: Object
    }
  },
  data() {
    return {
      baseUrl: "/api/wms/v1/master/cost-center-v2",
      options: [],
      selected: (this.multiple) ? [] : null
    };
  },
  methods: {
    get() {
      this.$http
        .get(this.baseUrl, {
          params: {
            order: "name",
            sort: "asc"
          }
        })
        .then(resp => {
          if (resp.code == 200) {
            resp.data.records.map(function (x) {
              return (x.label = x.code + " - " + x.name);
            });

            this.options = resp.data.records;
          } else {
            this.options = []
            console.log(resp.data);
          }
        });
    },
    label(option) {
      return option.label;
    },
  },
  watch: {
    selected(newVal) {
      this.$emit('selected', newVal);
    },
    value: {
      immediate: true,
      handler(newVal) {
        this.selected = newVal;
      }
    },
  },
  mounted() {
    this.get();
  }
};
</script>
