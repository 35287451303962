<template>
  <multiselect
    :filterable="true"
    :options="options"
    v-model="selected"
    :custom-label="label"
    :placeholder="placeholder" />
</template>

<script>
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    draw: {
      type: Number,
    },
    placeholder: {
      type: String
    },
    value: {
      type: Object
    },
    warehouseID: {
      type: Number,
    }
  },
  data() {
    return {
      baseUrl: "/api/wms/v1/master/warehouse-area-virtual-storage",
      options: [],
      selected: (this.multiple) ? [] : null
    };
  },
  methods: {
    get() {
      if (this.warehouseID) {
        this.$http
          .get(this.baseUrl, {
            params: {
              order: "name",
              sort: "asc",
              warehouse_id: this.warehouseID,
            }
          })
          .then(resp => {
            if (resp.code === 200) {
              resp.data.records.map(function (x) {
                return (x.label = x.code + " - " + x.name);
              });

              this.options = resp.data.records;
            } else {
              this.options = []
              console.log(resp.data);
            }
          });
      }
    },
    label(option) {
      return option.label;
    },
  },
  watch: {
    selected(newVal) {
      this.$emit('selected', newVal);
    },
    warehouseID(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.options = [];
        this.selected = this.multiple ? [] : null;
        this.get();
      }
    },
    value: {
      immediate: true,
      handler(newVal) {
        this.selected = newVal;
      }
    }
  },
  mounted() {
    this.get();
  }
};
</script>
